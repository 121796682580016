import React from "react";

const LoaderFadeInOut: React.FC<{ theme?: string; width?: string }> = ({
  theme = "warning",
  width = "36",
}) => {
  let color: string;

  switch (theme) {
    case "warning":
      color = "#F37A41";
      break;

    case "primary":
      color = "#551FC1";
      break;

    default:
      color = "#F37A41";

      break;
  }
  return (
    <div className={`w-${width}`}>
      <span className={`loader-fade-in-out`} style={{ color: color }}></span>
    </div>
  );
};

export default LoaderFadeInOut;
